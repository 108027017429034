/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// ------ THEME GENERIC CSS
// @import '../../../node_modules/@yaireo/tagify/src/tagify.scss';


$blue: #006fe6;
$theme-black: #262327;
$bg-grey : #F4F5F8;
$text-grey : #686868;
$maroon : #AA1D3D;
$green : #41955E;
$dark-grey : #505059;
$light-black : #222222;

// @import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Roboto:wght@400;700&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Anta&family=Kaushan+Script&family=Lato:wght@400;700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:wght@400;700&family=Roboto:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Protest+Revolution&family=Rubik:ital,wght@0,300..900;1,300..900&family=Titillium+Web:wght@400;700&&display=swap');

.poppins-fonts {
	font-family: "Poppins", sans-serif;
}

.anta-font {
	font-family: "Anta", sans-serif;
}

.openSans-font {
	font-family: "Open Sans", sans-serif;
}

.nunito-font {
	font-family: "Nunito", sans-serif;
}

.kaushanscript-font {
	font-family: "Kaushan Script", cursive;
}

.lato-font {
	font-family: "Lato", sans-serif;
}

.roboto-font {
	font-family: "Roboto", sans-serif;
}

.titillium-font {
	font-family: "Titillium Web", sans-serif;
}

.playfair-font {
	font-family: "Playfair Display", sans-serif;
}

.protest-font {
	font-family: "Protest Revolution", sans-serif;
}

.rubik-font {
	font-family: "Rubik", sans-serif;
}


.font-weight-500 {
	font-weight: 500 !important;
}

.text-purple {
	color: #7367f0 !important;
}

.bg-purple {
	background-color: #7367f0 !important;
}

.bg-theme-black {
	background: $theme-black !important;
	color: white !important;
}

.select__control {
	border-color: #d8d6de;
}

.btn-outline-primary {
	&:not(:disabled) {
		&:not(.disabled) {
			&:focus {
				background-color: transparent;
			}
		}
	}
}

// ------ THEME CUSTOM CSS

.theme-toggle-link {
	i {
		font-size: 1.45rem;
	}
}

.dropdown-notification {
	a {
		i {
			font-size: 1.45rem;
		}
	}
}

div#user-profile {
	.card {
		height: 100%;
	}
}

.card-full-height {
	.card {
		height: 100%;
	}
}

img.header-image {
	max-height: 256px;
	object-fit: contain;
}

.brand-logo {
	img {
		width: 41px;
		height: 21px;
	}
}

.cl-stat-ul {
	li {
		display: inline-block;
		margin: 0 15px 10px 0;
		vertical-align: top;
		font-size: 14px;

		&:last-child {
			margin-bottom: 0 !important;
		}

		.cl-stat-icon {
			margin: 0 6px 0 0;
			width: 28px;
			height: 28px;
			line-height: 28px !important;
			border-radius: 3px;
			text-align: center;
			color: white;
			font-size: 16px;

			&.missing {
				color: #e25149;
				background: #ffdad8;
				border: 1px solid #febcb8;
			}

			&.draft {
				color: #cea200;
				background: #fff9d0;
				border: 1px solid #fff192;
			}

			&.reviewed {
				color: #1f8bff;
				background: #edf6ff;
				border: 1px solid #c4e1ff;
			}

			&.revision {
				color: #7367f0;
				background: #e4e2ff;
				border: 1px solid #d4d0ff;
			}

			&.published {
				color: #0aac0f;
				background: #d3ffcc;
				border: 1px solid #93ff98;
			}
		}
	}
}

.card-statistics {
	.statistics-body {
		padding: 10px 0 !important;
	}
}

.aph-chklist-point {
	background: white;
	color: #000000;
	border-radius: 5px;
	font-size: 14px;
	margin: 0 0 13px 0;
	box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
	font-size: 12px;
	display: flex;

	i {
		color: #c70000;
		font-size: 17px;
		line-height: normal;
	}

	&.selected {
		i {
			color: #01a601;
		}
	}
}

.aph-chklist-tabs {
	.nav-item {
		.nav-link {
			.check-counter {
				display: inline-block;
				border: 1px solid;
				border-radius: 5px;
				padding: 0 5px;
				color: #495057;

				&.green {
					border-color: #01a601;
					color: #01a601;
				}
			}
		}
	}
}

.roles-table {
	.rdt_TableHead {
		.rdt_TableHeadRow {
			>.rdt_TableCol {
				&:not(:first-child) {
					padding: 10px 10px 10px 8px;
				}
			}
		}
	}
}

.avatar {
	&.bg-light-purple {
		color: #7367f0 !important;
	}

	&.bg-light-orange {
		color: #de9000 !important;
	}
}

.bg-light-purple {
	background: #eae8ff !important;
	color: #7367f0 !important;
}

.bg-light-green {
	background: #e0ffdb !important;
	color: #14741c !important;
}

.bg-light-red {
	background: #ffd8d8 !important;
	color: #b01b1b !important;
}

.bg-light-grey {
	background: #e3e3e3 !important;
	color: #5c5c5c !important;
}

.bg-light-orange {
	background: #fff3dc !important;
	color: #de9000 !important;
}

.avatar-content {
	i {
		font-size: 19px;
		height: 24px;
		width: 24px;
		line-height: 27px;
		text-align: center;
	}
}

.aph-hist-link.dropdown-toggle {
	&::after {
		display: none;
	}
}

.aph-hist-dd {
	min-width: 300px;
	max-height: 240px;
	overflow-y: auto !important;
	max-width: 100%;
	border-radius: 8px;
	padding: 10px;
	background: white;
	box-shadow: 0 5px 25px rgb(34 41 47 / 25%);
}

.aph-hist-ol {
	li {
		position: relative;
		margin: 0;
		padding-bottom: 10px;
		padding-left: 20px;
		display: flex;
		color: #858585;
		justify-content: space-between;
		font-size: 12px;

		&::before {
			content: "";
			background-color: #e8e8e8;
			position: absolute;
			bottom: 0;
			top: 0;
			left: 6px;
			width: 2px;
		}

		&::after {
			content: "\f058";
			font-family: "Font Awesome 5 Pro";
			font-weight: 400;
			font-size: 13px;
			position: absolute;
			top: 1px;
			left: 1px;
			height: 19px;
			padding: 1px 0;
			background: white;
			line-height: 20px;
			color: green;
		}

		&:last-child {
			padding-bottom: 0;
		}

		&.missing {
			&::after {
				content: "\f057";
				color: #ee1101 !important;
			}
		}
	}
}

.hist-date {
	min-width: 55px;
}

.hist-un {
	font-weight: 600;
	color: var(--font-black);
	line-height: normal;
}

.last-action-btn {
	i {
		font-size: 14px;
		margin-right: 0 !important;
	}

	&:hover {
		background: none !important;
	}

	&:focus {
		background: none !important;
	}
}

.aph-chklist-icon {
	text-align: center;
	min-width: 40px;
	background: #ffd5d1;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;

	&.selected {
		background: #c1ffc1;
	}
}

.flatpickr-calendar {
	.flatpickr-day.inRange {
		box-shadow: -5px 0 0 #ffd3d8, 5px 0 0 #ffd3d8 !important;

		&:hover {
			box-shadow: -5px 0 0 #ffd3d8, 5px 0 0 #ffd3d8 !important;
		}
	}
}

.flatpickr-calendar {
	.flatpickr-day.inRange {
		background: #ffd3d8 !important;
		border-color: #ffd3d8 !important;

		&:hover {
			background: #ffd3d8 !important;
			border-color: #ffd3d8 !important;
		}
	}
}

.prevData {
	font-size: 14px !important;
	border-left: 1.5px solid #ccc;
	padding-left: 5px;

	svg {
		width: 15px;
		height: 15px;
		position: relative;
		top: -1px;
		color: red;
	}
}

.prevData.increase {
	svg {
		color: green;
	}
}

.effect-1,
.effect-2,
.effect-3 {
	border-left: 3px solid #de3b3b !important;
}

.fallback-spinner {
	.loading {
		width: 170px !important;
		height: 170px !important;
		position: absolute;

		.effect-1 {
			width: 170px !important;
			height: 170px !important;
		}

		.effect-2 {
			width: 170px !important;
			height: 170px !important;
		}

		.effect-3 {
			width: 170px !important;
			height: 170px !important;
		}
	}
}

.co-tabs {
	.nav-item {
		&:not(:last-child) {
			margin: 0 10px 10px 0;
		}

		.nav-link {
			background: #f2f2f2;
			border: 0;
			text-align: left;
			justify-content: start;

			.co-tabs-heading {
				font-weight: 700;
			}

			.d-flex {
				.badge {
					min-width: 34px !important;
					display: inline-block;
					justify-content: center;
				}
			}

			&:hover {
				.co-tabs-heading {
					color: #ae1123;
				}
			}

			&.active {
				background: none;
				box-shadow: 0 4px 18px -4px rgb(162 162 162 / 65%);
				color: #ae1123;
			}
		}
	}
}

.co-tab-content {
	.tab-pane {
		.card {
			border: 1px solid #eee;
		}
	}
}

.collapse-btn {
	svg {
		transition: 0.4s ease-in-out;
	}

	&.collapseOpen {
		svg {
			transform: rotate(180deg);
		}
	}
}

.badge.published {
	color: #0aac0f !important;
	background: #d3ffcc !important;
	border: 1px solid #93ff98 !important;
}

.badge.draft {
	color: #cea200 !important;
	background: #fff9d0 !important;
	border: 1px solid #fff192 !important;
}

.badge.reviewed {
	color: #1f8bff !important;
	background: #edf6ff !important;
	border: 1px solid #c4e1ff !important;
}

.badge.revision {
	color: #7367f0 !important;
	background: #e4e2ff !important;
	border: 1px solid #d4d0ff !important;
}

.badge.missing {
	color: #e25149 !important;
	background: #ffdad8 !important;
	border: 1px solid #febcb8 !important;
}

.misc-wrapper {
	.brand-logo {
		align-items: center;
	}
}

.badge.badge-md {
	font-size: 14px;
}

.badge.badge-lg {
	font-size: 18px;
}

.card-body {
	.user-chats {
		max-height: 500px !important;
	}
}

.apexcharts-datalabel-label {
	font-family: "Montserrat" !important;
	text-transform: uppercase;
}

.ap-dd-menu {
	.dropdown-menu {
		.dropdown-item {
			i {
				width: 18px;
				text-align: center;
			}
		}
	}
}

.react-dataTable.perf-tbl {
	.rdt_TableBody {
		.rdt_TableRow {
			.rdt_TableCell {
				height: auto;
			}
		}
	}
}

.device-info {
	i {
		width: 20px;
		text-align: center;
	}
}

/* -- TIMER CIRCLE CSS START -- */

@keyframes l-rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(-180deg);
	}

	100% {
		-webkit-transform: rotate(-180deg);
	}
}

@keyframes r-rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(-180deg);
	}
}

@keyframes fadeout {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

$circle: 70;
$border-width: 4;
$timer: 10;

.circle {
	width: $circle+px;
	height: $circle+px;
	min-width: $circle+px;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 999px;
	box-shadow: inset 0 0 0 5px rgb(194 194 194 / 50%);
}

.l-half,
.r-half {
	float: left;
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		border: $border-width + px solid #474747;
		-webkit-animation-duration: $timer + s;
		-webkit-animation-iteration-count: 1;
		-webkit-animation-timing-function: linear;
		-webkit-animation-fill-mode: forwards;
	}
}

.r-half {
	right: 0;
}

.l-half:before {
	border-right: none;
	border-top-left-radius: 999px;
	border-bottom-left-radius: 999px;
	-webkit-transform-origin: center right;
	-webkit-animation-name: l-rotate;
}

.r-half:before {
	border-left: none;
	border-top-right-radius: 999px;
	border-bottom-right-radius: 999px;
	-webkit-transform-origin: center left;
	-webkit-animation-name: r-rotate;
}

/* -- TIMER -- */

.count {
	position: absolute;
	width: 100%;
	line-height: $circle + px;
	text-align: center;
	font-weight: 800;
	font-size: (($circle - ($border-width * 2)) / 2) + px;
	font-family: Helvetica;
	color: #fff;
	z-index: 2;
	-webkit-animation: fadeout 0.5s ($timer + 1) + s 1 linear;
	-webkit-animation-fill-mode: forwards;
}

/* -- TIMER CIRCLE CSS END -- */

.dark-layout {
	.circle {
		box-shadow: inset 0 0 0 5px rgb(23 30 50);
	}
}

.target-list {
	li {
		display: inline-block;

		&:not(:last-child) {
			margin: 0 20px 0 0;
		}

		i {
			margin: 0 5px 0 0;
		}
	}
}

.react-dataTable.lb-tbl {
	.rdt_TableBody {
		.rdt_TableRow {
			&:nth-child(1) {
				.rdt_TableCell:first-child {
					background: url("./../images/icons/badge_first.png");
					background-size: 40px;
					background-position: 30px;
					background-repeat: no-repeat;
				}
			}

			&:nth-child(2) {
				.rdt_TableCell:first-child {
					background: url("./../images/icons/badge_second.png");
					background-size: 40px;
					background-position: 30px;
					background-repeat: no-repeat;
				}
			}

			&:nth-child(3) {
				.rdt_TableCell:first-child {
					background: url("./../images/icons/badge_third.png");
					background-size: 40px;
					background-position: 30px;
					background-repeat: no-repeat;
				}
			}
		}
	}
}

.react-dataTable::-webkit-scrollbar {
	width: 12px;
}

.react-dataTable::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.react-dataTable::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


// ========= CUSTOM DARK MODE CSS ========

.dark-layout {
	.text-primary {
		color: #ff2525 !important;
	}

	a {
		color: #ff2525;
	}

	.btn-link {
		color: #ff2525;
	}

	.aph-hist-ol {
		li {
			color: #b2b2b2;

			&::after {
				background: #283046 !important;
				color: #89fe89 !important;
			}

			&::before {
				background-color: #969696;
			}

			&.missing {
				&::after {
					color: #ff2525 !important;
				}
			}
		}
	}

	.aph-chklist-tabs {
		.nav-item {
			.nav-link {
				.check-counter {
					color: #808080;
				}
			}
		}
	}

	.aph-chklist-point {
		background: #283046;
		color: #ccc;

		i {
			color: #ff2525;
		}

		&.selected {
			i {
				color: #89fe89;
			}
		}
	}

	.aph-chklist-icon {
		background: rgb(255 153 147 / 23%) !important;

		&.selected {
			background: rgba(40, 199, 111, 23%) !important;
		}
	}

	.dropdown-menu {
		.dropdown-item {
			&:hover {
				color: #ff576a;
			}

			&:focus {
				color: #ff576a;
			}
		}
	}

	.badge {
		border: 0 !important;

		&.published {
			background: rgba(40, 199, 111, 0.12) !important;
			color: #28c76f !important;
		}

		&.missing {
			background: rgb(255 153 147 / 12%) !important;
			color: #fa6a63 !important;
		}

		&.reviewed {
			background: rgb(125 189 252 / 12%) !important;
			color: #429dff !important;
		}

		&.revision {
			background: rgb(114 105 250 / 12%) !important;
			color: #aca4ff !important;
		}

		&.draft {
			background: rgb(252 232 97 / 12%) !important;
			color: #fccf27 !important;
		}
	}

	.co-tab-content {
		.tab-pane {
			.card {
				border: 1px solid #424242;
			}
		}
	}

	.css-1s2u09g-control {
		background-color: hsl(224deg 27% 22%);
		border-color: #404656;
	}

	.btn-primary {
		border-color: rgb(174 17 35 / 30%) !important;
		background-color: rgb(174 17 35 / 30%) !important;
		color: #f4f4f4 !important;
	}

	.bg-light-purple {
		background: rgba(114, 105, 250, 0.12) !important;
		color: #aca4ff !important;
	}

	.co-tabs {
		.nav-item {
			.nav-link {
				background: #3a4052;
			}
		}
	}

	.nav-pills {
		.nav-item {
			.nav-link {
				&.active {
					background-color: rgb(174 17 35 / 30%) !important;
					box-shadow: 0 4px 18px -4px rgb(142 0 0 / 65%);
				}
			}
		}
	}

	.cl-stat-ul li {
		.cl-stat-icon.missing {
			background: rgb(255 153 147 / 12%);
			border: 1px solid #ff2f2400;
			color: #fa6a63;
		}

		.cl-stat-icon.draft {
			background: rgb(252 232 97 / 12%);
			border: 1px solid #fff19200;
			color: #fccf27;
		}

		.cl-stat-icon.reviewed {
			background: rgb(125 189 252 / 12%);
			border: 1px solid #c4e1ff00;
			color: #429dff;
		}

		.cl-stat-icon.revision {
			background: rgb(114 105 250 / 12%);
			border: 1px solid #d4d0ff00;
			color: #aca4ff;
		}

		.cl-stat-icon.published {
			background: rgba(40, 199, 111, 0.12) !important;
			border: 1px solid #93ff9800;
			color: #28c76f !important;
		}

		.fa-pennant.cl-stat-icon.bg-light.missing {
			background: rgb(170 170 170 / 12%) !important;
		}
	}
}

.smart-exp-row {
	li {
		display: inline-block;
		width: 35%;
		margin-bottom: 5px;
	}
}

.ag-theme-alpine {
	.ag-root-wrapper {
		border-radius: 0;
		border: 0;
	}
}

.uc_heading {
	background: #f1f1f1;
	padding: 15px 10px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	margin-bottom: 0;
}

.lsi_heading {
	background: #f1f1f1;
	padding: 15px 10px;
	border-radius: 6px;
}

.uc_heading_body {
	border: 1px solid #ebe9f1;
	border-top: 0;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	padding: 15px 10px;
}

.action_on_headings {
	.uc_heading_wrapper {
		margin-bottom: 35px;
	}

	&:last-child {
		margin-bottom: 0px;
	}
}

.content_inr_hd_wrapper {
	padding-left: 55px;
	padding-top: 20px;
	position: relative;

	&:before {
		content: '';
		background: #e5e5e5;
		height: 100%;
		width: 3px;
		display: block;
		position: absolute;
		left: 25px;
		top: 0;
	}
}

.content_inr_hd {
	margin-bottom: 20px;
	position: relative;
	margin-left: 11px;

	&:before {
		content: '';
		background: #e5e5e5;
		width: 24px;
		height: 3px;
		display: block;
		position: absolute;
		left: -40px;
		top: 7px;
	}
}

.content_inr_hd_row {
	.dot-icon {
		margin-top: 7px;
		color: #a3a3a3;
		margin-right: 7px;
	}
}

.custom_tooltip {
	background: #b5b5b580;
	border-radius: 50%;
	width: 21px;
	height: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
}

// ========= MEDIA QUERIES ========

@media (max-width: 768px) {
	.co-tabs {
		.nav-item {
			width: 100%;

			&:not(:last-child) {
				margin: 0 0 10px 0;
			}
		}
	}

	.cl-stat-ul li {
		width: 100%;
	}
}

@media (max-width: 576px) {
	.header-navbar .navbar-container .dropdown-menu-media {
		max-width: 100%;
	}
}


/*== start of code for tooltips ==*/
.tool {
	position: relative;
	width: 21px;
	height: 21px;
	background: #b5b5b580;
	color: #646464;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
	left: 50%;
	opacity: 0;
	position: absolute;
	z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
	opacity: 1;
	transform: scale(1) translateY(0);
	z-index: 100;
}


/*== pointer tip ==*/
.tool::before {
	border-style: solid;
	border-width: 1em 0.75em 0 0.75em;
	border-color: #3E474F transparent transparent transparent;
	bottom: 100%;
	content: "";
	margin-left: -0.5em;
	transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26), opacity .65s .5s;
	transform: scale(.6) translateY(-90%);
}

.tool:hover::before,
.tool:focus::before {
	transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
}


/*== speech bubble ==*/
.tool::after {
	background: #3E474F;
	border-radius: .25em;
	bottom: 136%;
	color: #EDEFF0;
	content: attr(data-tip);
	margin-left: -8.75em;
	padding: 1em;
	transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
	transform: scale(.6) translateY(50%);
	width: 17.5em;
}

.tool:hover::after,
.tool:focus::after {
	transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26);
}

.react-dataTable [role="columnheader"] {
	overflow: unset !important;
}

.react-dataTable>div {
	// overflow-y: unset !important;
	overflow-x: auto !important;
}

.react-dataTable.cell_height .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	height: 100%;
}

// ag grid
.ag-root-wrapper {
	border-radius: 10px;
}

.ag-cell-value {
	color: #6e6b7b;
	font-weight: 300;
}

.ag-header-row {
	background-color: #f3f2f7 !important;
	color: #6e6b7b !important;
}

.ag-theme-alpine.my_datatable_1 {
	height: calc(100vh - 319px);
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	height: auto !important;
}

.ai-engine-output-box {

	// br + br{ display: none; }
	.badge[class*=bg-] a {
		color: #ae1123;
	}

	.collapse {
		display: block !important;
	}
}

// For Default Theme
$ai-tool-scrap-result-bg: #edf2f7;
$ai-tool-api-result-bg: #e9ffd0;
$ai-tool-api-result-heading: #59A502;
$ai-tool-api-result-text: #6e6b7b;
$ai-tool-api-main-box-boder: none;

// @error $ai-tool-scrap-result-bg;
.main-fetch-result-box {
	.bg-fetch-result {
		background-color: $ai-tool-scrap-result-bg;
	}
}

.main-fetch-api-output-box {
	border: $ai-tool-api-main-box-boder;

	.bg-fetch-api-output {

		background: $ai-tool-api-result-bg !important;
		color: $ai-tool-api-result-heading !important;

		.api-result-text {
			color: $ai-tool-api-result-text;
		}

		.btn-primary {
			border-color: #ae1123 !important;
			background-color: #ae1123 !important;
		}
	}
}

// For Dark Theme
$ai-tool-scrap-result-bg: #000000;
$ai-tool-api-result-bg: #f9f9f900;
$ai-tool-api-result-heading: #59A502;
$ai-tool-api-result-text: #ffffff;
$ai-tool-api-result-text: #ffffff;
$ai-tool-api-main-box-boder: 1px solid;

body.dark-layout {
	.main-fetch-result-box {
		.bg-fetch-result {
			background-color: $ai-tool-scrap-result-bg;

			.btn-primary {
				border-color: #ae1123 !important;
				background-color: #ae1123 !important;
			}
		}
	}

	.main-fetch-api-output-box {
		border: $ai-tool-api-main-box-boder;

		.bg-fetch-api-output {

			background: $ai-tool-api-result-bg !important;
			color: $ai-tool-api-result-heading !important;

			.api-result-text {
				color: $ai-tool-api-result-text;
			}

			.btn-primary {
				border-color: #ae1123 !important;
				background-color: #ae1123 !important;
			}
		}
	}

}

.bg-fetch-result {

	.hl-box:empty,
	.shadow:empty {
		display: none !important;
	}
}

// ====== CONTENT UPDATE TOOL CSS START ======= 


$theme_blue: #0D99FF;
$theme_orange: #FF3E1D;
$theme_light_orange: #ffefec;

p {
	line-height: 25px !important;
}

.card {
	.card-body {
		line-height: 25px;
	}
}

.content-tool-ul {
	li {
		display: inline-block;
		margin-right: 10px;
	}
}

.highlighted {
	background: #ffdddd;
}

.text-orange {
	color: $theme_orange;
}

.bg-orange {
	background: $theme_orange !important;
	border-color: $theme_orange !important;
}

.toast-width-extend {
	width: 430px !important;
}

.splash-screen-text {
	margin-bottom: 50px;
}

.splash-screen-text {
	.splash-screen-heading {
		font-size: 44px;
		font-weight: 900;
		padding: 0 24px;
		margin-bottom: 10px;
	}
}

.splash-screen-text {
	.splash-screen-heading {
		+ {
			p {
				font-size: 20px;
			}
		}
	}
}

.splash-screen-img {
	width: 450px;
}


.content-tool-tabs.nav-tabs {
	.nav-link {
		border-bottom: 2px solid rgba(0, 0, 0, 0.35) !important;

		&:hover {
			color: $theme_blue;
		}

		&:after {
			display: none;
		}
	}
}

.content-tool-tabs.nav-tabs {
	.nav-link.active {
		border-bottom: 2px solid $theme_blue !important;
		color: $theme_blue;
	}
}

.ll-btn {
	background: none;
	border: 0;
	color: #585858;

	&:hover {
		color: $theme_blue;
	}
}

.ll-btn.add {
	span {
		position: relative;
		top: -2px;
	}
}

.ll-btn.delete {
	&:hover {
		color: red;
	}
}

.ll-label-div {
	display: flex;
	justify-content: space-between;
}

.ll-repeated-div {
	display: flex;
	align-items: center;

	&.ll-repeated-div-in {
		&:not(:nth-last-of-type(1)) {
			margin-bottom: 8px;
		}
	}
}

.ll-ul>.ll-repeated-div>input {
	border-radius: 0;
}

.ll-ul>.ll-repeated-div:first-child>input {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 5px !important;
}

.ll-ul>.ll-repeated-div:last-child>input {
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.form-label {
	color: #585858;
	font-weight: 600;
}

.lsi-badge {
	background: rgba(255, 239, 225, 1);
	color: #FF9F43;
	border-radius: 16px;
	margin: 0 0 13px 0;
	padding: 6px 10px;
	font-size: 12px;
	font-weight: 500;
	// display: inline-block;
	line-height: 1;
	vertical-align: middle;

	i {
		color: #FF9F43;
		font-size: 17px;
		line-height: normal;
		margin-left: 10px;
	}

	&.selected {
		i {
			color: #28C76F;
		}
	}

	&:hover {
		color: #FF9F43 !important;
		background: #ffe5cc;
	}
}

.lsi-badge-body {
	position: relative;
	top: -2px;
}

.lsi-badge.selected {
	background: #DCF6E8;
	color: #28C76F;

	&:hover {
		color: #28C76F !important;
		background: #b7f6d4;
	}
}

.content-tool-wrap {
	.card {
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.15);
		box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.15);
	}

	.accordion-margin {
		.accordion-item {
			border-radius: 10px;
			overflow: hidden;
			border: 1px solid rgba(0, 0, 0, 0.15) !important;
			box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.15) !important;

		}
	}
}

.rc-accordion.accordion {
	.accordion-collapse .accordion-body {
		padding: 22px;
	}

	.accordion-button {
		font-size: 14px;
		// color: #9CA3AF;
		background: #F8FAFC;
	}
}

.rc-accordion.accordion {
	.accordion-collapse {
		.accordion-body {
			.ds-div {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.accordion-button.collapsed {
	background: white !important;
}

.ds-div {
	border-radius: 15px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	padding: 20px;
	margin-bottom: 16px;
}

.ds-header {
	color: rgba(0, 0, 0, 0.55);
	font-weight: 600;
	font-size: 15px;
	margin-bottom: 30px;
}

.ds-header {
	i {
		font-size: 7px;
		color: #C65864;
		position: relative;
		top: -2px;
		margin-right: 10px;
	}
}

.ds-body {
	margin-bottom: 26px;
}

.ds-footer {
	display: flex;
	justify-content: space-between;
}

.ds-right-ul li {
	display: inline-block;
	margin-right: 6px;

	&:last-child {
		margin-right: 0;
	}
}

.btn-link {
	cursor: pointer;
	background-color: transparent !important;
	background: none !important;
	border: 0 !important;
	color: #6e6b7b !important;
	padding: 0 !important;
	font-weight: 400;

	&:hover {
		box-shadow: none !important;
		color: #434343 !important;
	}

	&:focus {
		background-color: transparent !important;
		background: none !important;
		box-shadow: none !important;
		color: #434343 !important;
	}
}

.btn-link.text-danger {
	color: red !important;
}

.ds-li-icon.btn-link {
	padding: 0 5px !important;
	font-weight: 300;
}

.gs-box {
	border-radius: 10px;
	border: 1px solid var(--day-field-stroke, rgba(0, 0, 0, 0.25));
	display: inline-block;
	overflow: hidden;
}

.gs-box-inr {
	border-top: 10px solid #28C76F;
	display: flex;
	align-items: center;
	padding: 12px;
}

.score-part {
	text-align: left;
	margin-left: 10px;
}

.gs-heading {
	font-weight: 600;
}

.gs-score {
	color: #475467;
	font-size: 46px;
	line-height: 40px;
}

.ds-dd-menu {
	max-width: 400px;
	overflow: hidden;
	font-size: 12px;
}

.ds-dd-menu {
	a {
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			color: $theme_orange !important;
			background-color: $theme_light_orange !important;
		}
	}
}

.ds-dd-menu {
	hr {
		margin-bottom: 0;
		margin-top: 5px;
	}
}

.cursor-grab {
	cursor: grab;
}

.outline-list-ul {
	li {
		border: 0 !important;
		background: #f8f8fa !important;
		margin-bottom: 15px;
		border-radius: 0.357rem !important;
	}
}

.outline-list-single {
	display: flex;
	align-items: center;
}

.outline-list-body {
	display: flex;
	align-items: center;
	width: 100%;
}

.outline-list-icons {
	display: flex;
	align-items: center;
}

.outline-list-single {
	.cursor-grab {
		i {
			font-size: 18px;
			padding: 10px;
		}
	}
}

.outline-list-icons {
	i.btn-link {
		padding: 5px !important;
		font-size: 16px;
		margin-left: 10px;
	}
}

.content-tool-steps {
	.bs-stepper-header {
		position: relative;
		justify-content: space-between;
		overflow: hidden;
		padding-left: 0 !important;
		padding-right: 0 !important;

		.step {
			background: #f8f8f8 !important;
			position: relative;
			z-index: 2;
			padding: 0 20px;
		}

		.line {
			background: #f8f8f8 !important;
			position: relative;
			z-index: 2;
			display: none;

			svg {
				width: 30px;
				height: 30px;
			}
		}

		&:before {
			content: '';
			position: absolute;
			background: #ccc;
			width: 100%;
			height: 1px;
			z-index: 1;
		}
	}

	.bs-stepper-content {
		display: none;
	}
}

.content-tool-steps .bs-stepper-header .line svg {
	width: 30px;
	height: 30px;
}


:root {
	--card-line-height: 1.2em;
	--card-padding: 1em;
	--card-radius: 0.5em;
	--color-green: #558309;
	--color-gray: #e2ebf6;
	--color-dark-gray: #c4d1e1;
	--radio-border-width: 2px;
	--radio-size: 1.5em;
}

.ot-radio {
	font-size: inherit;
	margin: 0;
	position: absolute;
	left: 15px;
	top: 24px;
}

.ot-label {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	transition: 0.3s ease-in-out;
	z-index: 3;
	margin-bottom: 20px;
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
	.ot-radio {
		-webkit-appearance: none;
		-moz-appearance: none;
		background: #fff;
		border: 1px solid rgba(0, 0, 0, 0.25);
		border-radius: 50%;
		cursor: pointer;
		height: var(--radio-size);
		outline: none;
		transition:
			background 0.2s ease-out,
			border-color 0.2s ease-out;
		width: var(--radio-size);

		&::after {
			border: var(--radio-border-width) solid #fff;
			border-top: 0;
			border-left: 0;
			content: '';
			display: block;
			height: 0.75rem;
			left: 27%;
			position: absolute;
			top: 49%;
			transform:
				rotate(45deg) translate(-50%, -50%);
			width: 0.375rem;
		}

		&:checked {
			background: var(--color-green);
			border-color: var(--color-green);
		}
	}

	.ot-label:hover .ot-radio {
		border-color: var(--color-green);

		&:checked {
			border-color: var(--color-green);
		}
	}

	.ot-label .ot-radio {
		&:checked {
			border-color: var(--color-green);
		}
	}
}

.ot-label-inr {
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	font-size: 13px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	padding: 70px 20px 20px 20px;
	transition: border-color 0.2s ease-out;
}

.ot-label:hover .ot-label-inr {
	border-color: var(--color-green);
}

.ot-radio:checked~.ot-label-inr {
	border-color: var(--color-green);
}

.ot-radio:disabled~.ot-label-inr {
	color: var(--color-dark-gray);
	cursor: default;
}

.ot-radio:disabled~.ot-label-inr .plan-type {
	color: var(--color-dark-gray);
}

.ot-label:hover .ot-radio:disabled~.ot-label-inr {
	border-color: var(--color-gray);
	box-shadow: none;
}

.ot-label:hover .ot-radio:disabled {
	border-color: var(--color-gray);
}

.ot-label-inr p {
	margin-bottom: 6px;
}

.ot-name {
	position: absolute;
	top: 23px;
	left: 45px;
	font-size: 17px;
	font-weight: 500;
}

.ot-label-inr:before {
	content: '';
	background: #ccc;
	width: 100%;
	height: 0.3px;
	position: relative;
	top: -12px;
}

// ====== CONTENT UPDATE TOOL CSS END ======= 

// ====== BATCH CONTENT PROCESS CSS START ======= 

.dropzone {
	cursor: pointer;
	background-color: #f8f8f8;
	border: 1px dashed #979797;
	border-radius: 0.357rem;
	display: flex;
	min-height: 150px;
	align-items: center;
	justify-content: center;
}

.empty-placeholder {
	padding: 30px 0;

	img {
		width: 400px;
	}
}

.no-process-img {
	width: 400px;
}

.btn-success:disabled,
.btn-success.disabled {
	background-color: #d7d7d7 !important;
	border-color: #d7d7d7 !important;
}

// ====== BATCH CONTENT PROCESS CSS END ======= 


.color_picker_wrapper {
	border: 1px solid #d8d6de;
	width: fit-content;
	padding-right: 10px;
	border-radius: 0.357rem;
	height: 38px;
	position: relative;
	overflow: hidden;
	cursor: pointer;

	.color_box {
		width: 38px;
		height: 38px;
	}

	.color_picker_label {}

	input[type="color"] {
		width: 38px;
		height: 38px;
		padding: 0;
		outline: none;
		-webkit-appearance: none;
		border: none;
	}

	input[type="text"] {
		border: none;
		outline: none;
		flex: 1;
		width: 100%;
		padding-left: 10px;

	}

	input[type="color"]::-webkit-color-swatch-wrapper {
		padding: 0;
	}

	input[type="color"]::-webkit-color-swatch {
		border: none;
		border-right: 1px solid #ccc;
	}
}

// ====== FEATURED IMAGE GALLERY CSS START ======= 

.fi-gallery-ul li {
	display: inline-block;
	width: 256px;
	margin: 0 29px 29px 0;
	position: relative;
}

.fi-gallery-ul {
	overflow: hidden;
	position: relative;
	left: 10px;
}

.fi-gallery-img {
	border: 1px solid #dedede;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
}

.fi-gallery-img a {
	transition: 0.4s ease-in-out;
	position: absolute;
	opacity: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: rgb(0 0 0 / 51%);
	color: white;
	font-weight: bold;
	font-size: 18px;
}

.fi-gallery-img a:hover {
	opacity: 1;
	transition: 0.4s ease-in-out;
}

.empty-placeholder i {
	font-size: 65px;
}

.btn-fi-generate+.ui-loader .loader {
	left: 25px;
}

.btn-fi-generate+.ui-loader {
	display: inline;
}

.custom-card-cont-updt {
	color: #000;
}

.custom-card-cont-updt p,
.custom-card-cont-updt li {
	font-size: 18px;
	line-height: 30px !important;
	margin: 20px 0;
}

.custom-card-cont-updt {
	table {
		width: 100%;

		th,
		td {

			border: 1px solid #d3d3d3 !important;
			padding: 0 5px !important;

		}
	}
}

// .custom-card-cont-updt em {
// 	color: #ff0000;
// 	padding: 10px;
// 	font-weight: 700;
// 	display: block;
// 	border-top:1px dashed;
// 	border-bottom:1px dashed;
// 	background-color: #e6e6e6;
// }

// ========= MEDIA QUERIES ========

@media (max-width: 768px) {
	.co-tabs {
		.nav-item {
			width: 100%;

			&:not(:last-child) {
				margin: 0 0 10px 0;
			}
		}
	}

	.cl-stat-ul {
		li {
			width: 100%;
		}
	}

	.tool::after {
		font-size: .75em;
		margin-left: -5em;
		width: 10em;
	}
}

@media (max-width: 576px) {
	.header-navbar {
		.navbar-container {
			.dropdown-menu-media {
				max-width: 100%;
			}
		}
	}
}


// content update tool
.headings_list {
	padding: 0;

	li {
		background-color: #f1f1f1;
		color: #000;
		list-style: none;
		padding: 10px;
		margin-bottom: 5px;

		p {
			font-size: 14px;
			line-height: 20px !important;
		}
	}
}

input {
	&::placeholder {
		font-size: 12px !important;
	}
}

.react-tagsinput {
	border-radius: 0.357rem !important;
	border-color: #d8d6de !important;
}

.react-tagsinput--focused {
	border-color: #ae1123 !important;
}

.react-tagsinput-tag {
	background: #DCF6E8 !important;
	color: #28C76F !important;
	border: none !important;
}

.react-tagsinput-input {
	width: auto !important;
	min-width: none !important;
	max-width: 100% !important;
}

// sop checker tool

.react-dataTable {
	.rdt_TableHead {
		.rdt_TableHeadRow {
			background-color: #f3f2f7;
			border-top: 1px solid;
			border-color: #ebe9f1;
		}
	}
}


.sop-pills.nav-pills {
	>li {
		.nav-link {
			border-radius: 0;
			box-shadow: none;

			&:not(.active) {
				background: #e9e9e9;
			}
		}
	}
}

.sopCell {
	padding: 10px 10px 10px 0;
	line-height: 20px;
}

.bg-light-warning-1 {
	background: rgba(255, 159, 67, 0.12) !important;
	color: #ff9f43 !important;
}

//  EEAT Calculator 



.bg-blue {
	background: $blue !important;
}

.text-maroon {
	color: $maroon !important;
}

.bg-maroon {
	background: $maroon !important;
}

.text-green {
	color: $green !important;
}

.bg-green {
	background-color: $green !important;
}

.bg-grey {
	background: $bg-grey !important;
}

.text-grey {
	color: $text-grey !important;
}

.text-dark-grey {
	color: $dark-grey !important;
}

.text-light-black {
	color: $light-black !important;
}

.fs-16 {
	font-size: 16px;
}


.last-checked-today {
	font-size: 12px;
}

.website-factor-badge {
	font-weight: normal;
	padding: 6px 13px;
}

.website-factor-perc {
	font-weight: 700;
}

.website-factor-single {
	padding: 15px;
	background-color: #ffffff;
	border-radius: 6px;
	box-shadow: 0px 2px 25px #0000001a;
}

.badges-single {
	padding: 15px;
	background-color: #ffffff;
	border-radius: 6px;

}

.badges-single.pass {
	color: $green;
}

.badges-single.pass .website-factor-perc {
	color: $green;
}

.badges-single.pass .website-factor-badge {
	background: $green;
}

.badges-single.fail .website-factor-perc {
	color: $maroon;
}

.badges-single.fail .website-factor-badge {
	background: $maroon;
}


.badges-single.pass .website-factor-perc {
	color: $green;
}

.website-factor-single.pass .website-factor-badge {
	background: $green;
}

.website-factor-single.fail .website-factor-perc {
	color: $maroon;
}

.website-factor-single.fail .website-factor-badge {
	background: $maroon;
}

/* =========== RATING WIDGET CSS START ========= */
.progress-bar-circle {
	display: block;
	margin: 0 auto;
	overflow: hidden;
	transform: rotate(-90deg) rotateX(180deg);
	background: none;
}

.progress-bar-circle circle {
	stroke-dashoffset: 0;
	transition: stroke-dashoffset 1s ease;
	stroke: #70f1b4;
	stroke-width: 13px;
}

.progress-bar-circle .bar {
	stroke: #e7e7e7;
}

.progressdiv {
	position: relative;
}

.progressdiv:after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	content: attr(data-percent);
	font-weight: bold;
	font-size: 35px !important;
	color: var(--black);
}

.wa-rating-widget {
	display: flex;
	align-items: center;
}

.rdt_TableBody {
	min-height: calc(100vh - 200px);
}

// Workflow New Style Start
.agent-container {
	background-color: #f7f7f7;
	border-radius: 6px;
	padding: 12px 12px 1px;
}

.flow-container {
	width: 100%;
}

.form-container {
	background-color: #fff;
	border-radius: 8px;
	padding: 8px;
	border: 1px solid #ddd;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	gap: 8px;
	position: sticky;
	bottom: 0;
	left: 0;
}

.form-input {
	flex: 1;
	border: none;
	outline: none;
}

.form-button {
	width: 36px;
	height: 36px;
	background-color: #000;
	border-radius: 8px;
	padding: 6px;
	border: none;
	outline: none;
}

.send-icon {
	width: 20px;
	height: 20px;
}

.agent_avatar {
	border-radius: 8px;
	width: 40px;
	height: 40px;
}

.manager-container h2 {
	font-size: 17px;
	font-weight: 700;
	margin-bottom: 0px;
}

.manager-flow {
	position: relative;
}

.manager-flow:not(:nth-of-type(1)) {
	padding: 12px 0 12px 18px;
	margin-left: 20px;
	border-left: 2px solid #ddd;
}

.manager-flow:not(:nth-of-type(1))::before {
	content: "";
	position: absolute;
	top: -48px;
	bottom: 0;
	left: 0px;
	margin: auto;
	width: 18px;
	height: 1px;
	border-top: 1px dashed #ddd;
}

.manager-flow:nth-last-of-type(1)::before {
	top: 0px;
}

.agent-in {
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 8px 16px 8px 8px;
	border-radius: 8px;
	background-color: #fff;
	width: fit-content;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.task-state {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 0;
}

.task-state span {
	color: $maroon;
}

.label-text {
	font-size: 14px;
	color: #646464;
	margin: 12px 0;
}

.label-text span {
	font-weight: 600;
	color: #000;
}

.wa-agent-task-status {
	color: #00a400;
	position: absolute;
	left: -10px;
	top: -47px;
	bottom: 0;
	margin: auto;
	height: fit-content;
	font-size: 18px;
}

/* HTML: <div class="loader"></div> */
.agent-loader-wrapper {
	width: 40px;
	display: flex;
	justify-content: center;
	margin-left: 8px;
	height: auto;
}

.agent-loader {
	width: 6px;
	aspect-ratio: 1;
	border-radius: 50%;
	animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
	0% {
		box-shadow: 16px 0 rgba(22, 22, 22), -16px 0 rgba(22, 22, 22, 0.133);
		background: rgba(22, 22, 22)
	}

	33% {
		box-shadow: 16px 0 rgba(22, 22, 22), -16px 0 rgba(22, 22, 22, 0.133);
		background: rgba(22, 22, 22, 0.133)
	}

	66% {
		box-shadow: 16px 0 rgba(22, 22, 22, 0.133), -16px 0 rgba(22, 22, 22);
		background: rgba(22, 22, 22, 0.133)
	}

	100% {
		box-shadow: 16px 0 rgba(22, 22, 22, 0.133), -16px 0 rgba(22, 22, 22);
		background: rgba(22, 22, 22)
	}
}

.loader-container {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 99999;
	background-color: #000;
	opacity: .25;
}

// Workflow New Style End